<template>
  <div class="container">
    <div class="img">
      <img src="./img/人才中心_BANNER.jpg" alt="" />
    </div>
    <div class="zou"  >
      <div class="han">人才中心</div>
      <div class="ying">
        Talent center
      </div>
    </div>

    <div class="div1">人才中心</div>
    <div class="div2">Talent center</div>

    <div class="one">
      <div class="a">
        <div class="aaa">
          <!--<img src="./img/图层 545.png" alt="" />-->
          <div class="aa">团队介绍</div>
          <div class="bb"></div>
          <div class="cc">Team Introduction</div>
        </div>

        <div class="bbb">
          <div class="dd"></div>
          <div class="ee">充满活力的团队</div>
        </div>

        <div class="ccc">
          <img v-for="(img1, index) in modelone" :src="img1.pic" :key="index" />
        </div>
      </div>

      <div class="b">
        <div class="aaa">
          <!--<img src="./img/图层 545.png" alt="" />-->
          <div class="aa">培训发展</div>
          <div class="bb"></div>
          <div class="cc">Training development</div>
        </div>

        <div class="bbb">
          <div class="dd"></div>
          <div class="ee">完整的培训体系</div>
        </div>

        <div class="qqq">
          <div>
            <el-carousel
              style="height: 20.2292vw; width: 37vw"
              :interval="4000"
              arrow="always"
              @change="change"
            >
              <el-carousel-item
                style="height: 20.2292vw; width: 37vw;
"
                v-for="(img, index) in modelstwo"
                :key="index"
              >
                <img
                  class="cover"
                  style="height: 20.2292vw; width: 37vw"
                  v-bind:src="img.pic"
                />
                <div
                  style="
                    background: black !important;
                    height: 20%;
                    margin-top: -10%;
                    font-size: 1.1458vw;
                    color: white;
                    text-align: center;
                    opacity: 0.75;
                    padding-top: 2%;
                  "
                >
                  {{ img.title }}
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>

          <div class="shu">
            <div
              :class="{ oo: true, active: index == active }"
              :key="index"
              v-for="(item, index) in modelstwo"
            >
              <img :src="index == active ? pics1[index] : pics[index]" alt="" />
              <div>{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="c">
        <div class="aaa">
          <!--<img src="./img/图层 545.png" alt="" />-->
          <div class="aa">成长机会</div>
          <div class="bb"></div>
          <div class="cc">Growth opportunities</div>
        </div>

        <div class="bbb">
          <div class="dd"></div>
          <div class="ee">全周期职业发展规划</div>
        </div>

        <div class="three">
          <div class="three1">
            <img
              :class="'img' + index"
              v-show="index >= 0 && index < 3"
              :key="index"
              v-for="(img2, index) in modelsthree"
              :src="img2.pic"
            />
          </div>
        </div>
      </div>
      <div class="d">
        <div class="aaa">
          <div class="aa">招聘途径</div>
          <div class="bb"></div>
          <div class="cc">Recruitment approach</div>
        </div>

        <div class="bbb">
          <div class="dd"></div>
          <div class="ee">加入我们(点击下方图标查看招聘职位↓)</div>
        </div>

        <div class="fff">
          <div class="eee">
            <a
              target="_blank"
              :key="index"
              v-for="(item1, index) in modelsfour"
              v-bind:href="item1.link"
              v-show="index >= 0 && index < 3"
            >
              <img :class="'left' + index" :src="item1.pic" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPersonnelList } from "../../api/rencaizhongxin";
export default {
  name: "Index",
  data() {
    return {
      data: [],
      modelstwo: [],
      modelone: [],
      modelsthree: [],
      modelsfour: [],
      active: 0,
      pics: [
        require("./img/11.png"),
        require("./img/22.png"),
        require("./img/31.png"),
        require("./img/41.png"),
      ],
      pics1: [
        require("./img/1.png"),
        require("./img/2.png"),
        require("./img/3.png"),
        require("./img/4.png"),
      ],
    };
  },
  methods: {
    change(current, pre) {
      console.log(current);
      this.active = current;
    },
  },
  watch:{
    $route:{
      deep:true,
      immediate:true,
      handler(newV,olV) {
        // console.log(this.active)
        this.active = newV.name;
        // console.log(newV,"hello大家好")
        // console.log(this.active,"??????")
      }
    }
  },
  mounted() {
    getPersonnelList().then((res) => {
      // console.log(res.data);
      let data = res.data;
      console.log(data);

      this.data = data;
      this.modelstwo = data.modelstwo;
      // console.log(data.pic)
      this.modelone = data.modelone;
      // console.log(data.modelone)
      this.modelsthree = data.modelsthree;
      this.modelsfour = data.modelsfour;
    });
  },
};
</script>

<style lang="less" scoped>
  .zou{
    width: 11.1979vw;
    height: 12.9551vh;
    opacity: 0.8;
    background-color: #d7000f;
    position: absolute;
    margin-left: 5.5625vw;
    margin-top: -8.4633vh;
  }
  .han{
    width: 10.7083vw;
    height: 1.4063vw;
    font-size: 1.5625vw;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: left;
    color: #ffffff;
    margin-top:0.6771vw ;
    margin-left: 0.8854vw;
  }
  .ying{
    width: 10.8125vw;
    height: 1.1979vw;
    font-size: 1.45vw;
    font-family: PingFang SC, PingFang SC-Light;
    font-weight: 300;
    text-align: left;
    color: #ffffff;
    margin-left: 0.8854vw;
    margin-top: 0.6vw;
  }

.active {
  background-color: red;
  /deep/ div {
    color: #fff !important;
  }
}

.img img {
  width: 100%;
  height: 48.6891vh;
}
.div1 {
  width: 21.25vw;
  height: 2.0833vw;
  font-size: 2.2917vw;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  color: #d7000f;
  margin-top: 4.1146vw;
  margin-left: 45.5vw;
}
.div2 {
  width: 21.5625vw;
  height: 1.0417vw;
  font-size: 1.3542vw;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #646464;
  margin-top: 1.0417vw;
  margin-left: 46vw;
}
.one {
  /*height: 50vw;*/
  margin-top: 5.2083vw;
  margin-bottom: 7.8125vw;
}
.a,
.b,
.c,
.d {
  width: 100%;
  margin-bottom: 9.2188vw;
}
.aaa {
  height: 3vw;
  display: flex;
  margin-left: 7.8125vw;
  background-image: url("./img/图层 545.png");
  background-repeat:no-repeat;
  background-size:30vw 3vw;
}
.aa {
  width: 6.7708vw;
  /*height: 1.3021vw;*/
  font-size: 1.4583vw;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #ffffff;
  margin: auto 0;
  margin-left: 2vw!important;
  margin-right: -2vw!important;

}
.bb {
  width: 0.0521vw;
  height: 1.5625vw;
  background: #ffffff;
  /*margin-top: 0.625vw;*/
  margin-right: 0.5208vw;
  margin: auto 0;
  margin-left: 2vw!important;
  margin-right: -1vw!important;
}
.cc {
  width: 15.625vw;
  /*height: 1.0417vw;*/
  font-size: 1.3542vw;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #ffffff;
  margin-top: 0.6771vw;
  margin: auto 0;
  margin-left: 2vw!important;
}
.bbb {
  display: flex;
  margin-top: 3.5417vw;
  margin-left: 7.8125vw;
}
.dd {
  width: 0.3125vw;
  height: 1.6667vw;
  background: #d7000f;
}
.ee {
  width: 26.0417vw;
  height: 1.25vw;
  font-size: 1.3542vw;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #000000;
  margin-left: 0.5208vw;
  margin-top: 0.1042vw;
}
.ccc {
  width: 84.2708vw;
  margin-left: 7.8125vw;
  margin-top: 2.0313vw;
}
.ccc img {
  width: 19.1146vw;
  height: 13.5417vw;
  margin-right: 1.0417vw;
}
.three {
  height: 41.6667vw;
}
.three1 {
  position: relative;
  margin-left: 7.8125vw;
  margin-top: 2.0313vw;
}
.img0 {
  width: 40vw;
  height: 40vw;
  margin-right: 1.5vw;
}
.img1 {
  position: absolute;
  width: 40vw;
  height: 19.5vw;
  margin-bottom: 1vw;
}
.img2 {
  width: 40vw;
  height: 19.5vw;
}
.fff {
  height: 14.0625vw;
}
.eee {
  position: relative;
}
.left0 {
  width: 20vw;
  height: 10vw;
  margin-left: 7.7813vw;
  margin-top: 3vw;
  margin-right: 1.5208vw;
}
.left1 {
  width: 20vw;
  height: 10vw;
  margin-right: 1.5208vw;
}
.left2 {
  width: 36vw;
  height: 9.375vw;
  margin-left: 2.0313vw;
  position: absolute;
  margin-top: 2.6042vw;
}

.qqq {
  width: 84.2708vw;
  margin-left: 7.8125vw;
  margin-top: 2.0313vw;
  display: flex;
}

.shu {
  margin-left: 4.6875vw;
}
.oo {
  border: 0.0521vw solid #d7000f;
  width: 37vw;
  height: 3.125vw;
  border-radius: 0.7813vw;
  margin-bottom: 2.4vw;
  display: flex;
}
.shu .oo img {
  margin: auto 0;
  margin-left: 1.8667vw!important;
  width: 2.5vw;
  height: 2.5vw;
  line-height: 2.5vw;
}
.shu .oo div {
  width: 37vw;
  font-size: 1.6667vw;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #d7000f;
  margin: auto 0;
  margin-left: 2.6875vw!important;
}
</style>
